import _ from 'lodash';
import { getIcon, paymentMethodCardInfo } from '@/utils/paymentMethod';

export const ACTION_FETCH_DASHBOARD = 'dashboard/fetchDashboard';
export const MUTATION_UPSERT_PAYMENT_METHOD = 'dashboard/upsertPaymentMethod';
const DEFAULT_DISABLE_CANCEL_SUBSCRIPTION_BUTTON = false;

export const dashboard = {
  namespaced: true,
  state: {
    dashboardData: {
      deviceInfo: {},
      plan: {},
      nextPaymentDate: '',
      serviceEndDate: '',
      serviceEndTime: '',
      paymentMethod: {},
      features: [],
      accessPeriod: {},
      userInfo: [],
    },
    disableCancelSubscriptionButton: false,
  },
  getters: {
    paymentMethodIcon(state) {
      return getIcon(state.dashboardData.paymentMethod);
    },
    paymentMethodCardText(state) {
      return paymentMethodCardInfo(state.dashboardData.paymentMethod);
    },
    paymentMethodCardExist(state) {
      return !!state.dashboardData.paymentMethod;
    },
    cardType(state) {
      return state.dashboardData.paymentMethod.cardType;
    },
  },
  mutations: {
    upsertDashboard(state, dashboardData) {
      state.dashboardData.plan = dashboardData.subscription;
      state.dashboardData.deviceInfo = dashboardData.deviceInfo;
      state.dashboardData.paymentMethod = dashboardData.paymentMethod;
      state.dashboardData.features = dashboardData.features;
      state.dashboardData.accessPeriod = dashboardData.accessPeriod;
      state.dashboardData.userInfo = dashboardData.userInfo;
    },
    upsertPaymentCardType(state, cardType) {
      state.dashboardData.paymentMethod = _.assignIn(state.dashboardData.paymentMethod, {
        type: cardType,
      });
    },
    upsertPaymentMethod(state, paymentMethod) {
      state.dashboardData.paymentMethod = paymentMethod;
    },
    upsertDisableCancelSubscriptionButton(state, data) {
      state.disableCancelSubscriptionButton = data;
    },
  },
  actions: {
    fetchDashboard({
      commit,
      dispatch,
      rootState,
      state,
    }, forceReload = false) {
      // check if the subscription is loaded
      if (state?.dashboardData?.plan?.subscriptionId && !forceReload) {
        return;
      }

      return rootState.api.stardust.get('/dashboard')
        .then((response) => {
          commit('upsertDashboard', response.data);

          commit('deviceManagement/upsertWPA', response.data.deviceInfo.wpaPskPassphrase, { root: true });

          if (response.data.deviceInfo && response.data.deviceInfo.dpskMigrationNeeded) {
            dispatch('migrateDPSK');
          }

          return response.data;
        });
    },
    migrateDPSK({ rootState }) {
      return rootState.api.stardust.put('/network/cloudpath-dpsk');
    },
    getDisableCancelSubscriptionButton({ commit }) {
      const isDisabledCancelSubscriptionButton = this.state.global.publicConfig.DISABLE_CANCEL_SUBSCRIPTION_BUTTON ? this.state.global.publicConfig.DISABLE_CANCEL_SUBSCRIPTION_BUTTON : DEFAULT_DISABLE_CANCEL_SUBSCRIPTION_BUTTON;
      commit('upsertDisableCancelSubscriptionButton', JSON.parse(isDisabledCancelSubscriptionButton));
    },
  },
};
